import React from 'react';
import './DefaultPage.css';
import DefaultPage from './DefaultPage';
import heroVideo from '../../videos/personal.mp4'
import HeroSection from '../HeroSection';
import Motivation from '../Motivation';
import BosseLife from '../BosseLife';
import Follow from '../Follow'; 

function Personal(props) {
    return (
        <>
            <DefaultPage sections={
                <>
                    <HeroSection
                        heroVideo={heroVideo}
                        body={
                            <>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1">LEGENDS</h1>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h1 data-aos="zoom-in" className="display-3">ARE BUILT AT THE</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1 red-font">EASTSIDE</h1>
                                </div>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1 red-font">MECCA</h1>
                                </div>
                            </>
                        }
                    />
                    <Motivation/>
                    <BosseLife/>
                    <Follow/>
                </>
            }
            />
        </>
    );
}

export default Personal;