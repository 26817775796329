import React from 'react';
import DefaultPage from './DefaultPage';
import Map from '../Map';
import Follow from '../Follow';
import heroVideo from '../../videos/anfahrt.mp4'
import HeroSection from '../HeroSection';

function Address() {
    return (
        <DefaultPage
            sections={
                <>
                 <HeroSection
                        heroVideo={heroVideo}
                        body={
                            <>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1">LEGENDS</h1>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h1 data-aos="zoom-in" className="display-3">ARE BUILT AT THE</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1 red-font">EASTSIDE</h1>
                                </div>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1 red-font">MECCA</h1>
                                </div>
                            </>
                        }
                    />
                    <Map></Map>
                    <Follow></Follow>
                </>
            }
        />
    );
}

export default Address;