import './AddBanner.css';
import React from 'react';

const adText = 'Du willst zu uns wechseln? Profitiere vom Wechselbonus: 2 Monate gratis bei Vorlage der Kündigung deines bisherigen Studios im EastsideMecca!';

const AddBanner = () => {
  return (
    <div className="ad-banner-container">
      <p className="ad-text mt-3 text-nowrap">{adText}</p>
    </div>
  );
};

export default AddBanner;