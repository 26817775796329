import React from 'react';
import './Section.css'

function Section(props) {
    var class_color
    if (props.color === "white") {
        class_color = "black"
    } else {
        class_color = "white"
    }
    return (
        <div className={`outter-styling-${props.color} pt-3 pb-3 d-flex justify-content-center text-${class_color}`}>
            <div className='container-lg row'>
                    {props.body}
            </div>
        </div>
    );
}

export default Section;