import React from 'react'
import './HeroSection.css'
import { Button } from './Button'

/**
 * Funktion zum Erstellen der Hero Section
 * @returns heroSection
 */

function HeroSection(props) {
    return (
        <div className='hero-container text-white'>
            <video src={props.heroVideo} autoPlay loop muted playsInline />
            <div className="position-absolute bottom-0 start-50 translate-middle-x text-center">
                <div className="text-center">
                    {props.body}
                </div>
                <div className="m-3">
                    <Button text="LEGENDE WERDEN!" size="btn-lg" customClasses="m-1" onClick='/kontakt' />
                </div>
            </div>
        </div>
    )
}

export default HeroSection;
