import "./DefaultPage.css"
import Top from "../Top"

/**
 * Funktion zum Erstellen des Default Page Templates. Hilft bei einheitlichem Design abseits der Hauptseite.
 * @returns DefaultPage
 */

function DefaultPage(props) {
    return (
        <>
        <div>
            { props.body }
            { props.sections }
        </div>
        <Top></Top>
        </>
    )
}

export default DefaultPage
