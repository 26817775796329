import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Funktion um beim Rendern einer Seite nach oben zu scrollen. Wird in Defaultpage genutzt.
 * @returns Top of Page
 */

function Top() {
  /**
   * Setzen eines konstanten Wertes, zu dem gescrollt werden soll.
   */
    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
  /**
   * Useeffect zum ausführen des Scrollen nachdem alle DOM Elemente gerendert wurden.
   */
    useEffect(() => {
      onTop()
    }, [routePath]);
    return (
        null
    )
}

export default Top
