import React from 'react';
import Section from './Section';
import { Instagram, Facebook } from 'react-bootstrap-icons';


function Follow(props) {
    return (
        <Section
            color="white"
            body={
                <>
                    <div className='col-md-12 text-center my-auto'>
                        <h1 data-aos="zoom-in" className='display-1'> Folgt uns auf <br />
                            Social Media!
                        </h1>
                    </div>
                    <div data-aos="fade-right" className='col-md-6 md-auto text-center text-black'>
                        <a href="https://www.instagram.com/body_fight_gym_potsdam/" className="text-decoration-none" target="_blank" rel="noreferrer">
                            <Instagram size='80px' color="black" />
                            <h3 className='link-dark'>Instagram.</h3>
                        </a>
                    </div>
                    <div data-aos="fade-left" className='col-md-6 md-auto text-center text-black'>
                        <a href="https://de-de.facebook.com/bosselife/" className="text-decoration-none" target="_blank" rel="noreferrer">
                            <Facebook size="80px" color="black" />
                            <h3 className='link-dark'>Facebook.</h3>
                        </a>
                    </div>
                </>
            }
        />
    );
}

export default Follow;