import React from 'react'
import DefaultPage from './DefaultPage'

/**
 * Funktion zum Erstellen der Seite Datenschutz
 * @returns site Datenschute
 */

function Datenschutz() {
    return (
        <>
            <DefaultPage
                body={
                    <>
                        <div className='container-lg pt-3'>
                            <h2 class="wp-block-heading">Datenschutzerklaerung</h2>
                            <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfaehigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
                            <p>Gemaeß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veraenderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschraenkung, das Löschen oder die Vernichtung.</p>
                            <p>Mit der nachfolgenden Datenschutzerklaerung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualitaet eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
                            <p>Unsere Datenschutzerklaerung ist wie folgt gegliedert:</p>
                            <p>I. Informationen über uns als Verantwortliche<br />II. Rechte der Nutzer und Betroffenen<br />III. Informationen zur Datenverarbeitung</p>
                            <h3 class="wp-block-heading">I. Informationen über uns als Verantwortliche</h3>
                            <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
                            Body & Fight GYM Potsdam<br />Andreas Bosse<br />Gerlachstrasse 10<br />14480 Potsdam
                            <p>Telefon: 0331 290 22 43<br/><br />E-Mail: info@eastsidemecca.de
                            </p>
                            <h3 class="wp-block-heading">II. Rechte der Nutzer und Betroffenen</h3>
                            <p>Mit Blick auf die nachfolgend noch naeher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
                            <ul>
                                <li>auf Bestaetigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
                                <li>auf Berichtigung oder Vervollstaendigung unrichtiger bzw. unvollstaendiger Daten (vgl. auch Art. 16 DSGVO);</li>
                                <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemaeß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschraenkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
                                <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
                                <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
                            </ul>
                            <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfaenger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschraenkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhaeltnismaeßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfaenger.</p>
                            <p><strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong></p>
                            <h3 class="wp-block-heading">III. Informationen zur Datenverarbeitung</h3>
                            <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfaellt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>
                            <h4 class="jet-listing-dynamic-field__content">Cookie Manager </h4><p>Zur Einholung einer Einwilligung zum Einsatz von technisch nicht notwendigen Cookies auf der Website, setzt der Anbieter einen Cookie-Manager ein.</p>
                            <p>Bei dem Aufruf der Website wird ein Cookie mit den Einstellungsinformationen auf dem Endgeraet des Nutzers abgelegt, sodass bei einem weiteren Besuch die Abfrage in Bezug auf die Einwilligung nicht erfolgen muss.</p>
                            <p>Das Cookie ist erforderlich um eine rechtskonforme Einwilligung des Nutzers einzuholen.</p>
                            <p>Die Installation der Cookies kann der Nutzer durch Einstellungen seines Browsers verhindern bzw. beenden.</p>
                            <h4 class="jet-listing-dynamic-field__content">Cookies</h4><h5>a) Sitzungs-Cookies/Session-Cookies</h5>
                            <p>Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgeraet ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet.</p>
                            <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</p>
                            <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
                            <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalitaet unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
                            <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
                            <h5>b) Drittanbieter-Cookies</h5>
                            <p>Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitaeten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
                            <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
                            <h5>c) Beseitigungsmöglichkeit</h5>
                            <p>Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschraenken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen haengen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players aendern. Auch die hierfür erforderlichen Schritte und Maßnahmen haengen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
                            <p>Sollten Sie die Installation der Cookies verhindern oder einschraenken, kann dies allerdings dazu führen, dass nicht saemtliche Funktionen unseres Internetauftritts vollumfaenglich nutzbar sind.</p>
                            <h4 class="jet-listing-dynamic-field__content">Kontaktanfragen / Kontaktmöglichkeit</h4><p>Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschraenkt beantworten.</p>
                            <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
                            <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.</p>
                            <h4 class="jet-listing-dynamic-field__content">Serverdaten</h4><p>Aus technischen Gründen, insbesondere zur Gewaehrleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
                            <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
                            <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilitaet, Funktionalitaet und Sicherheit unseres Internetauftritts.</p>
                            <p>Die Daten werden spaetestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klaerung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
                            <h4 class="jet-listing-dynamic-field__content">Facebook</h4><p>Zur Bewerbung unserer Produkte und Leistungen sowie zur Kommunikation mit Interessenten oder Kunden betreiben wir eine Firmenpraesenz auf der Plattform Facebook.</p>
                            <p>Auf dieser Social-Media-Plattform sind wir gemeinsam mit der Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, verantwortlich.</p>
                            <p>Der Datenschutzbeauftragte von Facebook kann über ein Kontaktformular erreicht werden:</p>
                            <p><a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noreferrer noopener">https://www.facebook.com/help/contact/540977946302970</a></p>
                            <p>Die gemeinsame Verantwortlichkeit haben wir in einer Vereinbarung bezüglich der jeweiligen Verpflichtungen im Sinne der DSGVO geregelt. Diese Vereinbarung, aus der sich die gegenseitigen Verpflichtungen ergeben, ist unter dem folgenden Link abrufbar:</p>
                            <p><a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms/page_controller_addendum</a></p>
                            <p>Rechtsgrundlage für die dadurch erfolgende und nachfolgend wiedergegebene Verarbeitung von personenbezogenen Daten ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht an der Analyse, der Kommunikation sowie dem Absatz und der Bewerbung unserer Produkte und Leistungen.</p>
                            <p>Rechtsgrundlage kann auch eine Einwilligung des Nutzers gemaeß Art. 6 Abs. 1 lit. a DSGVO gegenüber dem Plattformbetreiber sein. Die Einwilligung hierzu kann der Nutzer nach Art. 7 Abs. 3 DSGVO jederzeit durch eine Mitteilung an den Plattformbetreiber für die Zukunft widerrufen.</p>
                            <p>Bei dem Aufruf unseres Onlineauftritts auf der Plattform Facebook werden von der Facebook Ireland Ltd. als Betreiberin der Plattform in der EU Daten des Nutzers (z.B. persönliche Informationen, IP-Adresse etc.) verarbeitet.</p>
                            <p>Diese Daten des Nutzers dienen zu statistischen Informationen über die Inanspruchnahme unserer Firmenpraesenz auf Facebook. Die Facebook Ireland Ltd. nutzt diese Daten zu Marktforschungs- und Werbezwecken sowie zur Erstellung von Profilen der Nutzer. Anhand dieser Profile ist es der Facebook Ireland Ltd. beispielsweise möglich, die Nutzer innerhalb und außerhalb von Facebook interessenbezogen zu bewerben. Ist der Nutzer zum Zeitpunkt des Aufrufes in seinem Account auf Facebook eingeloggt, kann die Facebook Ireland Ltd. zudem die Daten mit dem jeweiligen Nutzerkonto verknüpfen.</p>
                            <p>Im Falle einer Kontaktaufnahme des Nutzers über Facebook werden die bei dieser Gelegenheit eingegebenen personenbezogenen Daten des Nutzers zur Bearbeitung der Anfrage genutzt. Die Daten des Nutzers werden bei uns gelöscht, sofern die Anfrage des Nutzers abschließend beantwortet wurde und keine gesetzlichen Aufbewahrungspflichten, wie z.B. bei einer anschließenden Vertragsabwicklung, entgegenstehen.</p>
                            <p>Zur Verarbeitung der Daten werden von der Facebook Ireland Ltd. ggf. auch Cookies gesetzt.</p>
                            <p>Sollte der Nutzer mit dieser Verarbeitung nicht einverstanden sein, so besteht die Möglichkeit, die Installation der Cookies durch eine entsprechende Einstellung des Browsers zu verhindern. Bereits gespeicherte Cookies können ebenfalls jederzeit gelöscht werden. Die Einstellungen hierzu sind vom jeweiligen Browser abhaengig. Bei Flash-Cookies laesst sich die Verarbeitung nicht über die Einstellungen des Browsers unterbinden, sondern durch die entsprechende Einstellung des Flash-Players. Sollte der Nutzer die Installation der Cookies verhindern oder einschraenken, kann dies dazu führen, dass nicht saemtliche Funktionen von Facebook vollumfaenglich nutzbar sind.</p>
                            <p>Naeheres zu den Verarbeitungstaetigkeiten, deren Unterbindung und zur Löschung der von Facebook verarbeiteten Daten finden sich in der Datenrichtlinie von Facebook:</p>
                            <p><a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noreferrer noopener">https://www.facebook.com/privacy/explanation</a></p>
                            <p>Es ist nicht ausgeschlossen, dass die Verarbeitung durch die Meta Platforms Ireland Limited auch über die Meta Platforms, Inc., 1601 Willow Road, Menlo Park, California 94025 in den USA erfolgt.</p>
                            <h4 class="jet-listing-dynamic-field__content">Instagram</h4><p>Zur Bewerbung unserer Produkte und Leistungen sowie zur Kommunikation mit Interessenten oder Kunden betreiben wir eine Firmenpraesenz auf der Plattform Instagram.</p>
                            <p>Auf dieser Social-Media-Plattform sind wir gemeinsam mit der Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, verantwortlich.</p>
                            <p>Der Datenschutzbeauftragte von Instagram kann über ein Kontaktformular erreicht werden:</p>
                            <p><a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noreferrer noopener">https://www.facebook.com/help/contact/540977946302970</a></p>
                            <p>Die gemeinsame Verantwortlichkeit haben wir in einer Vereinbarung bezüglich der jeweiligen Verpflichtungen im Sinne der DSGVO geregelt. Diese Vereinbarung, aus der sich die gegenseitigen Verpflichtungen ergeben, ist unter dem folgenden Link abrufbar:</p>
                            <p><a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer noopener">https://www.facebook.com/legal/terms/page_controller_addendum</a></p>
                            <p>Rechtsgrundlage für die dadurch erfolgende und nachfolgend wiedergegebene Verarbeitung von personenbezogenen Daten ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht an der Analyse, der Kommunikation sowie dem Absatz und der Bewerbung unserer Produkte und Leistungen.</p>
                            <p>Rechtsgrundlage kann auch eine Einwilligung des Nutzers gemaeß Art. 6 Abs. 1 lit. a DSGVO gegenüber dem Plattformbetreiber sein. Die Einwilligung hierzu kann der Nutzer nach Art. 7 Abs. 3 DSGVO jederzeit durch eine Mitteilung an den Plattformbetreiber für die Zukunft widerrufen.</p>
                            <p>Bei dem Aufruf unseres Onlineauftritts auf der Plattform Instagram werden von der Facebook Ireland Ltd. als Betreiberin der Plattform in der EU Daten des Nutzers (z.B. persönliche Informationen, IP-Adresse etc.) verarbeitet.</p>
                            <p>Diese Daten des Nutzers dienen zu statistischen Informationen über die Inanspruchnahme unserer Firmenpraesenz auf Instagram. Die Facebook Ireland Ltd. nutzt diese Daten zu Marktforschungs- und Werbezwecken sowie zur Erstellung von Profilen der Nutzer. Anhand dieser Profile ist es der Facebook Ireland Ltd. beispielsweise möglich, die Nutzer innerhalb und außerhalb von Instagram interessenbezogen zu bewerben. Ist der Nutzer zum Zeitpunkt des Aufrufes in seinem Account auf Instagram eingeloggt, kann die Facebook Ireland Ltd. zudem die Daten mit dem jeweiligen Nutzerkonto verknüpfen.</p>
                            <p>Im Falle einer Kontaktaufnahme des Nutzers über Instagram werden die bei dieser Gelegenheit eingegebenen personenbezogenen Daten des Nutzers zur Bearbeitung der Anfrage genutzt. Die Daten des Nutzers werden bei uns gelöscht, sofern die Anfrage des Nutzers abschließend beantwortet wurde und keine gesetzlichen Aufbewahrungspflichten, wie z.B. bei einer anschließenden Vertragsabwicklung, entgegenstehen.</p>
                            <p>Zur Verarbeitung der Daten werden von der Facebook Ireland Ltd. ggf. auch Cookies gesetzt.</p>
                            <p>Sollte der Nutzer mit dieser Verarbeitung nicht einverstanden sein, so besteht die Möglichkeit, die Installation der Cookies durch eine entsprechende Einstellung des Browsers zu verhindern. Bereits gespeicherte Cookies können ebenfalls jederzeit gelöscht werden. Die Einstellungen hierzu sind vom jeweiligen Browser abhaengig. Bei Flash-Cookies laesst sich die Verarbeitung nicht über die Einstellungen des Browsers unterbinden, sondern durch die entsprechende Einstellung des Flash-Players. Sollte der Nutzer die Installation der Cookies verhindern oder einschraenken, kann dies dazu führen, dass nicht saemtliche Funktionen von Facebook vollumfaenglich nutzbar sind.</p>
                            <p>Naeheres zu den Verarbeitungstaetigkeiten, deren Unterbindung und zur Löschung der von Instagram verarbeiteten Daten finden sich in der Datenrichtlinie von Instagram:</p>
                            <p><a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer noopener">https://help.instagram.com/519522125107875</a></p>
                            <p>Es ist nicht ausgeschlossen, dass die Verarbeitung durch die Facebook Ireland Ltd. auch über die Meta Platforms, Inc., 1601 Willow Road, Menlo Park, California 94025 in den USA erfolgt.</p>
                            <h4 class="jet-listing-dynamic-field__content">YouTube</h4><p>Wir unterhalten bei YouTube eine Onlinepraesenz um unser Unternehmen sowie unsere Leistungen zu praesentieren und mit Kunden/Interessenten zu kommunizieren. YouTube ist ein Service der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein Tochterunternehmen der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA.</p>
                            <p>Insofern weisen wir darauf hin, dass die Möglichkeit besteht, dass Daten der Nutzer außerhalb der Europaeischen Union, insbesondere in den USA, verarbeitet werden. Hierdurch können gesteigerte Risiken für die Nutzer insofern bestehen, als dass z.B. der spaetere Zugriff auf die Nutzerdaten erschwert werden kann. Auch haben wir keinen Zugriff auf diese Nutzerdaten. Die Zugriffsmöglichkeit liegt ausschließlich bei YouTube.</p>
                            <p>Die Datenschutzhinweise von YouTube finden Sie unter</p>
                            <p><a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a></p>
                            <h4 class="jet-listing-dynamic-field__content">facebook</h4><p>Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.</p>
                            <p>Datenschutzerklaerung: <a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer noopener">https://www.facebook.com/policy.php</a></p>
                            <h4 class="jet-listing-dynamic-field__content">Instagram</h4><p>Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.</p>
                            <p>Datenschutzerklaerung: <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer noopener">https://help.instagram.com/519522125107875</a></p>
                            <h4 class="jet-listing-dynamic-field__content">YouTube</h4><p>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein Tochterunternehmen der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA</p>
                            <p>Datenschutzerklaerung: <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a></p>
                            <h4 class="jet-listing-dynamic-field__content">unpkg</h4><p>Wir nutzen das Content Delivery Network (CDN) unpkg, um schnellere Ladezeiten, eine verringerte Webserver-Auslastung und eine geringere Bandbreitenauslastung (und damit eine optimierte Performance) zu ermöglichen. Mit dem CDN werden Webseiteninhalte wie Skripte und Stylesheets über einen Verbund von regional verteilten Servern schneller ausgeliefert. Zu diesem Zweck muss dein Browser Verbindung zu den Servern von unpkg aufnehmen. Hierdurch erlangt unpkg Kenntnis darüber, dass über deine IP-Adresse diese Website aufgerufen wurde. Sollten die betreffenden Dateien bereits vorher auf einer anderen Seite vom CDN geladen worden sein, wird in der Regel dein Browser stattdessen automatisch auf die im Cache abgelegte Kopie zurückgreifen. Die verantwortliche Person von unpgk, Michael Jackson, versichert über seinen Twitter-Account, dass unpkg keinerlei anfallende Daten sammelt, auswertet oder speichert: https://twitter.com/mjackson/status/987342097773617152</p>
                            <h4 class="jet-listing-dynamic-field__content">Google Analytics</h4><p>In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
                            <p>Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
                            <p>Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Haeufigkeit des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb der EU bzw. des EWR.</p>
                            <p>Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitaeten zur Verfügung zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenhaengen.</p>
                            <p>Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem haelt Google unter</p>
                            <p><a href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank" rel="noreferrer noopener">https://www.google.com/intl/de/policies/privacy/partners</a></p>
                            <p>weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den Möglichkeiten, die Datennutzung zu unterbinden.</p>
                            <p>Zudem bietet Google unter</p>
                            <p><a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer noopener">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
                            <p>ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on laesst sich mit den gaengigen Internet-Browsern installieren und bietet Ihnen weitergehende Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste übermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie natürlich ebenfalls in dieser Datenschutzerklaerung.</p>
                            <h4 class="jet-listing-dynamic-field__content">Google-Maps</h4><p>In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
                            <p>Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</p>
                            <p>Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen, speichert Google über Ihren Internet-Browser ein Cookie auf Ihrem Endgeraet. Um unseren Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei können wir nicht ausschließen, dass Google Server in den USA einsetzt.</p>
                            <p>Im Falle einer von Ihnen erteilten Einwilligung für diese Verarbeitung ist Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage kann auch Art. 6 Abs. 1 lit. f DSGVO sein. Unser berechtigtes Interesse liegt in der Optimierung der Funktionalitaet unseres Internetauftritts.</p>
                            <p>Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln ist.</p>
                            <p>Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.</p>
                            <p>Zudem erfolgt die Nutzung von Google Maps sowie der über Google Maps erlangten Informationen nach den <a href="http://www.google.de/accounts/TOS" target="_blank" rel="noreferrer noopener">Google-Nutzungsbedingungen</a>&nbsp;<a href="https://policies.google.com/terms?gl=DE&amp;hl=de" target="_blank" rel="noreferrer noopener">https://policies.google.com/terms?gl=DE&amp;hl=de</a> und den <a href="https://www.google.com/intl/de_de/help/terms_maps.html" target="_blank" rel="noreferrer noopener">Geschaeftsbedingungen für Google Maps</a> https://www.google.com/intl/de_de/help/terms_maps.html.</p>
                            <p>Überdies bietet Google unter</p>
                            <p><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer noopener">https://adssettings.google.com/authenticated</a></p>
                            <p><a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy</a></p>
                            <p>weitergehende Informationen an.</p>
    
                            <p><a href="https://www.generator-datenschutzerklaerung.de" target="_blank" rel="noreferrer noopener">Muster-Datenschutzerklaerung</a> der <a href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html" target="_blank" rel="noreferrer noopener">Anwaltskanzlei Weiß &amp; Partner</a></p>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default Datenschutz