import '../../App.css'
import HeroSection from '../HeroSection'
import Highlights from '../Highlights';
import Personal from '../Personal';
import PricesMain from '../PricesMain';
import Partner from '../Partner';
import Follow from '../Follow';
import heroVideo from '../../videos/body_and_fight.mp4'
import { Button } from '../Button';

function Home() {
    return (
        <>
            <HeroSection
                heroVideo={heroVideo}
                body={
                    <>
                        <div className="container-lg">
                            <div className='col-md-12'>
                                <h1 data-aos="zoom-in" className="display-1">LEGENDS</h1>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h1 data-aos="zoom-in" className="display-3">ARE BUILT AT THE</h1>
                        </div>
                        <div className="col-md-12">
                            <h1 data-aos="zoom-in" className="display-1 red-font">EASTSIDE</h1>
                        </div>
                        <div className="col-md-12">
                            <h1 data-aos="zoom-in" className="display-1 red-font">MECCA</h1>
                        </div>
                    </>
                }
            />
            <PricesMain />
            <Highlights
                button={
                    <div className='col-md-12 text-center' >
                        <Button text="ERFAHRE MEHR!" size="btn-lg" customClasses="mt-5" onClick='/gym' />
                    </div>
                }
            />
            <Personal />
            <Follow />
            <Partner />
        </>
    )
}

export default Home;