import React from 'react'
import DefaultPage from './DefaultPage'

/**
 * Funktion zum Erstellen der Seite Impressum
 * @returns site Impressum
 */

function Impressum() {
    return (
        <DefaultPage
            body={
                <>
                  <div className='container-lg pt-2'>
                        <h1>Impressum</h1>
    
                        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                        <p>Andreas Bosse<br />
                            Body &amp; Fight GYM Potsdam<br />
                            Gerlachstrasse 10-12<br />
                            14480 Potsdam</p>
    
                        <h2>Kontakt</h2>
                        <p>Telefon: 0331 290 22 43<br />
                            E-Mail: info@eastsidemecca.de</p>
    
                        <h2>Umsatzsteuer-ID</h2>
                        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                            046/208/08054</p>
    
                        <h2>EU-Streitschlichtung</h2>
                        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    
                        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    
                        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
                  </div>
                </>
            }
        />
    )
}

export default Impressum
