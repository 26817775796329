import React from 'react'
import { Link } from "react-router-dom"

/**
 * Funktion zum Erstellen eines Buttons. Beispiel für Wiederverwendbarkeit des Codes.
 * @returns Button 
 */

export const Button = ({
    onClick,
    text,
    size,
    customClasses,
}) => {
    return (
        <Link to={ onClick } className='btn-mobile'>
            <button className={ `btn ${size} ${customClasses} bg-black border border-2 rounded text-white`}> { text } </button>
        </Link>
    )
}
