import React from 'react'
import Section from './Section';
import andi_1 from "../images/andi/andi_1.webp";
import andi_2 from "../images/andi/andi_2.webp";
import andi_3 from "../images/andi/andi_3.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Button } from './Button';


function Personal() {
    return (
        <Section
            color="black"
            body={
                <>
                    <div className='col-md-12 text-center'>
                        <h1 data-aos="zoom-in" className="display-4 red-font"> Wir haben die Loesung! </h1>
                    </div>
                    <div className="col-md-6 my-auto p-4 text-center">
                        <div id="carousel" className="carousel slide">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <LazyLoadImage src={andi_1}
                                        className="img-fluid"
                                        alt="andi wettbewerb 1"
                                        data-aos="zoom-in"
                                    />
                                </div>
                                <div class="carousel-item">
                                    <LazyLoadImage src={andi_2}
                                        className="img-fluid"
                                        alt="andi wettbewerb 2"
                                        data-aos="zoom-in"
                                    />
                                </div>
                                <div class="carousel-item">
                                    <LazyLoadImage src={andi_3}
                                        className="img-fluid"
                                        alt="andi wettbewerb 3"
                                        data-aos="zoom-in"
                                    />
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 my-auto">
                        <div>
                            <p>
                                Durch unser Personal Training bieten wir dir die Möglichkeit eines optimal auf dich zugeschnittenen Trainingsplans. Erstellung und Druchführung des Trainings finden durch den mehrfachen deutschen Meister und Mr. Universum Andreas Bosse statt.
                            </p>
                            <p>
                                <h4>Deine Vorteile auf einen Blick</h4>
                                - persönlicher und ständiger Ansprechpartner <br />
                                - Motivationshilfe und Steigerung <br />
                                - ständige Kontrolle der aktuellen Ergebnisse <br />
                                - Einzeltraining oder kleine Gruppen(maximal 2 Personen) <br />
                                - Training zu allen Zeiten möglich (Flexible Terminabsprache) <br />
                                - Ernährungshilfe + wechselnde Trainingsarten(z.B. PITT-Training/E.T.System-Training) <br />
                            </p>
                            <div className='text-center'>
                                <Button text="LEGENDE WERDEN!" size="btn-lg" customClasses="m-1" onClick='/kontakt' />
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    )
}

export default Personal