import React, { useRef, useEffect } from 'react';
import Section from './Section';
import emailjs from '@emailjs/browser'
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

function ContactForm(props) {
    const form = useRef();


    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);


    const doSubmit = (e) => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha_value) === true) {
            sendEmail(e)
        }
        else {
            alert('Captcha stimmt nicht. Bitte erneut versuchen.');
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('eastsidemecca', 'template_7z3t546', form.current, 'nWSuNE2so6bNrVO-S')
            .then((response) => {
                alert('E-Mail erfolgreich gesendet!', response);
            })
            .catch((error) => {
                alert('Fehler beim Senden der E-Mail:', error);
            });
    };

    return (
        <Section
            color="white"
            body={
                <>
                    <div className='col-md-12'>
                        <h1 data-aos="zoom-in" className='display-3 text-center'>Kontakt!</h1>
                        <div className="text-center">
                            <h3> Hast du Bock bei uns zu trainieren, oder noch Fragen? Melde dich einfach über das Kontakt Formular. </h3>
                        </div>
                        <div className="container-flex mt-5">
                            <form className="row g-3" ref={form} onSubmit={doSubmit}>
                                <div className="col-md-6">
                                    <label htmlFor="validName" className="form-label">Vorname</label>
                                    <input type="text" className="form-control" id="validName" placeholder="Max" required name="from_name" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validSurname" className="form-label">Nachname</label>
                                    <input type="text" className="form-control" id="validSurname" placeholder="Mustermann" required name="from_lastname" />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="validMember" className="form-label" >Mitglied?</label>
                                    <select name="member" className="form-select" id="validMember">
                                        <option value="Nein">Nein</option>
                                        <option value="Ja">Ja</option>
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="validEmail" className="form-label">E-Mail</label>
                                    <input type="email" className="form-control" id="validEmail" placeholder="max@mustermann.de" required name="email" />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="validPhone" className="form-label">Phone</label>
                                    <input type="mobile" className="form-control" id="validPhone" placeholder="0172/1234567" required name="mobile" />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="validText" className="form-label">Anfrage</label>
                                    <textarea className="form-control" id="validText" rows="4" required name="message" />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="validSubject" className="form-label">Grund</label>
                                    <select name="subject" className="form-select" id="validSubject">
                                        <option value="Nicht angegeben.">-- bitte wählen --</option>
                                        <option value="Probetraining">Probetraining</option>
                                        <option value="Personal Training">Personal Training</option>
                                        <option value="Partneranfrage">Partneranfrage</option>
                                        <option value="Vertragsfragen">Vertragsfragen</option>
                                        <option value="weitere Fragen">weitere Fragen</option>
                                    </select>
                                </div>
                                <div className='col-md-12'>
                                    <label htmlFor="user_captcha_input" className="form-label">Captcha</label>
                                    <input className="form-control" type="text" id="user_captcha_input" name="captcha" placeholder="Unten stehende Buchstabenfolge eingeben." autoComplete="off" required />
                                    <LoadCanvasTemplate reloadText="Neues Captcha generieren." />
                                </div>
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-lg border border-2 rounded text-white bg-black" type="submit">Absenden</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        />
    );
}

export default ContactForm;