import './App.css';
import { useEffect } from 'react';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './components/pages/Home'
import Impressum from './components/pages/Impressum'
import Datenschutz from './components/pages/Datenschutz'
import Contact from './components/pages/Contact';
import Gym from './components/pages/Gym'
import Opening from './components/pages/Opening';
import Address from './components/pages/Address';
import Personal from './components/pages/Personal';
import AddBanner from './components/AddBanner';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Aos from 'aos';
import 'aos/dist/aos.css'

/**
 * App.js: Hauptkomponente der Anwendung. Dients als Container für alle anderen Anwendungen.
 */

function App() {
  useEffect(() => {
    Aos.init({duration: 600});
  }, [])

  return (
    /**
     * Aufbau der Applogik mittels React Router. Weißt einen Link einer Ressource zu.
     */
    <>
      <Router>
        <AddBanner/>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/gym' exact element={<Gym />} />
          <Route path='/impressum' exact element={<Impressum />} />
          <Route path='/datenschutz' exact element={<Datenschutz />} />
          <Route path='/kontakt' exact element={<Contact />} />
          <Route path='/preise-und-oeffnungszeiten' exact  element={<Opening />} />
          <Route path='/anfahrt' exact  element={<Address />} />
          <Route path='/personal-training' exact  element={<Personal />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
