import React from 'react';
import Section from './Section';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { SignTurnRight } from 'react-bootstrap-icons';

const containerStyle = {
    width: '100%',
    height: '50vh'
};

const center = {
    lat: 52.368282,
    lng: 13.130027
};

function Map() {
    return (
        <Section
            color="black"
            body={
                <>
                    <div className='col-md-12'>
                        <LoadScript
                            googleMapsApiKey="AIzaSyDlLo-5flB8Hdxpn4ckJqTdgN4LOB7vIdY"
                        >
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={17}
                            >
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <div className='col-md-12 text-center p-2 fs-5'>
                        <p>
                            Gerlachstrasse 10-12 <br />
                            14480 Potsdam 
                        </p>
                    </div>
                    <div data-aos="zoom-in" className='col-md-6 text-center'>
                        <h1 className='display-2 red-font'>Mit dem Auto.</h1>
                        <p className='text-white'>
                            Gut erreichbar. < br />
                            Nahe der Nutheschnellstraße - Abfahrt Neuendorfer Str. < br />
                            Parkplätze direkt vor der Haustür. < br />
                            <a className='link-light' href='https://www.google.de/maps/place/Body+%26+Fight+GYM+Potsdam/@52.36826,13.1300401,17z/data=!3m1!4b1!4m6!3m5!1s0x47a85f101ffdc135:0x8d7631242779e839!8m2!3d52.36826!4d13.1300401!16s%2Fg%2F1hc2g188g' title='Routenplaner' target="_blank" rel="noreferrer"><SignTurnRight></SignTurnRight> Routenplaner.</a>
                        </p>
                    </div>
                    <div data-aos="zoom-in" className='col-md-6 text-center'>
                        <h1 className='display-2'>Mit den Oeffis.</h1>
                        <p className='text-white '>
                            10 Gehminuten vom Johannes-Kepler-Platz < br />
                            Mit den Buslinien 694 / 696 und 699 direkt bis vor die Tür. < br />
                        </p>
                    </div>
                </>
            }
        />
    )
}

export default Map;