import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom';

/**
 * Funktion zum Erstellen des Footers.
 * @returns footer
 */

function Footer() {
    return (
        <>
            <div className="footer">
                <div className="d-flex justify-content-center mt-1">
                    <div className="row">
                        <div className="col-md-4 text-white text-center">
                                <p className="fw-bold">Eastsidemecca</p>
                                <div className="text-truncate">
                                    Gerlachstrasse 10-12 <br />
                                    14480 Potsdam <br />
                                    Tel.: 0331 290 22 43 <br />
                                    E-Mail: info@eastsidemecca.de
                                </div>
                        </div>
                        <div className='col-md-4 text-white text-center'>
                                <p className='fw-bold'>Öffnungzeiten</p>
                                <div>
                                    <ul className="list-group">
                                        <li className="list-group-item bg-black text-white p-0">Mo - Fr 09.00 Uhr - 22.00 Uhr</li>
                                        <li className="list-group-item bg-black text-white p-0">Samstag	10.00 Uhr -	16.00 Uhr</li>
                                        <li className="list-group-item bg-black text-white p-0">Sonntag	10.00 Uhr - 14.00 Uhr</li>
                                        <li className="list-group-item bg-black text-white p-0">Feiertags	10.00 Uhr - 14.00 Uhr</li>
                                    </ul>
                                </div>
                        </div>
                        <div className='col-md-4 text-white text-center'>
                            <p className="fw-bold">Über uns</p>
                            <Link to='/impressum' className="nav-link">
                                <span>Impressum</span>
                            </Link>
                            <Link to='/datenschutz' className="nav-link">
                                <span>Datenschutz</span>
                            </Link>
                        </div>
                        <div className="col-md-12">
                            <div className="text-center p4 text-light mt-3">
                                <p> © 2023 Copyright: Eastsidemecca </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer

