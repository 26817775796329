import Section from './Section';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Cardio from "../images/cardio.webp";
import Freihanteln from "../images/freihanteln.webp";
import Gym from "../images/gym.webp";
import Supplements from "../images/supplements.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Highlights.css';


function Highlights(props) {

  var settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    className: "text-center",
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrow: false,
          centerMode: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          centerMode: false
        }
      }
    ]
  };

  function Slide(props) {
    return (
      <div>
        <div data-aos="zoom-in" className='d-flex flex-column'>
          <LazyLoadImage src={props.image}
            className="img-fluid rounded d-block"
            alt={props.alt}
            effect="blur"
            style={{ height: "50vh", objectFit: "cover" }}
          />
          <h3 className='mt-2'>{props.header}</h3>
          <p> {props.text} </p>
        </div>
      </div>
    );
  }

  return (
    <Section
      color="white"
      body={
        <>
          <div className='col-md-12 text-center'>
            <h1 data-aos="zoom-in" className='display-3'>Gym Highlights!</h1>
          </div>
          <Slider {...settings}>
            <Slide
              image={Cardio}
              header="Cardiobereich"
              alt="cardiobereich"
              text="Egal ob Erwärmung, Cut oder Nachweihnachtszeit - bei uns findet sich für jede Anforderung das richtige Gerät."
            />
            <Slide
              image={Freihanteln}
              header="Freie Gewichte."
              alt="Freihanteln"
              text="Keine Lust auf Hebel und Kabel? Wir bieten ein riesiges Angebot an Freihanteln und anderen freien Gewichte."
            />
            <Slide
              image={Gym}
              header="Profiausstattung."
              alt="gym"
              text="Dank unserer großen Auswahl an professionellen Geräten kannst du deine Muskelgruppen präzise trainieren."
            />
            <Slide
              image={Supplements}
              header="Beratung."
              alt="Protein"
              text="Mit jahrelanger Erfahrung in den Bereichen Fitness, Ernährung und Supplementierung stehen wir dir gerne bei Seite."
            />
          </Slider>
          {props.button}
        </>
      }
    />
  )
}

export default Highlights
