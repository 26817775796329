import { Button } from './Button'
import React from 'react'
import Section from './Section';
import Ropes from "../images/collage.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function PricesMain() {
    return (
        <Section
            color="black"
            body={
                <>
                    <div className="col-md-6 px-0 my-auto">
                        <LazyLoadImage src={Ropes}
                            className="img-fluid"
                            alt="seiltraining"
                            data-aos="zoom-in"
                        />
                    </div>
                    <div className="col-md-6 my-auto align-items-center">
                        <div className="text-center">
                            <h1 data-aos="zoom-in" className="display-3 red-font"> Finde deinen Weg! </h1>
                            <p className='mt-4 mb-4'> Egal ob du auf der Durchreise bist oder ein dauerhaftes Gym für deine Bedürfnisse suchst - bei uns bist du genau richtig. </p>
                            <Button text="DEIN WEG!" size="btn-lg" customClasses="" onClick='/kontakt' />
                        </div>
                    </div>
                </>
            }
        />
    )
}

export default PricesMain
