import Section from "./Section";
import LegalPower from "../images/shirt.webp"
import Sinob from "../images/sinoblogo.svg"
import PlanetFood from "../images/PLANET_FOOD_Logo.webp"
import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";

function Partner(props) {
    return (
        <div>
            <Section
                color="black"
                body={
                    <>
                        <div className="col-md-12 my-auto">
                            <div className="text-center">
                                <h1 data-aos="zoom-in" className="display-4 red-font"> Unsere Partner. </h1>
                            </div>
                        </div>
                        <div className="col-md-6 my-auto p-4 text-center">
                            <a
                                href="https://www.legalpower.de/de/index.php?fc=module&module=leoproductsearch&controller=productsearch&leoproductsearch_static_token=549b824c8cd7065d0612421ccad158c8&search_query=bosse&cate="
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LazyLoadImage src={LegalPower}
                                    className="img-fluid"
                                    alt="personal_training"
                                    data-aos="zoom-in"
                                />
                            </a>
                        </div>
                        <div className="col-md-6 my-auto p-4 text-center" data-aos="zoom-in">
                            <h2>Legal Power.</h2>
                            <p>Unser Partner im Punkt Bodybuilding und Fitness Bekleidung.</p>
                            <p>Neben der passenden Bekleidung für deinen Trainingsalltag findest du hier natürlich auch unseren aktuellen <a href="https://www.legalpower.de/de/index.php?fc=module&module=leoproductsearch&controller=productsearch&leoproductsearch_static_token=549b824c8cd7065d0612421ccad158c8&search_query=bosse&cate=" target="_blank" rel="noreferrer">Merchandise</a>.</p>
                        </div>
                        <div className="col-md-6 p-4 text-center" data-aos="zoom-in">
                            <h2> Sinob.</h2>
                            <p className="mt-2"> Unser Partner wenn es um Premium Whey und andere Supplements geht.</p>
                            <a
                                href="https://sinob.de/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LazyLoadImage src={Sinob}
                                    className="img-fluid"
                                    alt="personal_training"
                                />
                            </a>
                        </div>
                        <div className="col-md-6 p-4 text-center" data-aos="zoom-in">
                            <h2> Planet Food.</h2>
                            <p className="mt-2"> Unser Partner für Premiumfleisch zur Unterstüzung deines Ernährungsplanes.</p>
                            <a
                                href="https://planet-food.store/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LazyLoadImage src={PlanetFood}
                                    className="img-fluid"
                                    alt="personal_training"
                                />
                            </a>
                        </div>
                    </>

                }
            />

        </div>
    );
}

export default Partner;