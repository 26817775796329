import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Section from './Section';
import { Button } from './Button';
import "./Prices.css";

function Prices(props) {
    var settings = {
        dots: true,
        infinite: true,
        className: "text-center",
        centerMode: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrow: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                    centerMode: false
                }
            }
        ]
    };

    function PriceCard(props) {
        return <>
            <div data-aos="zoom-in" className='d-flex flex-column border border-white border-2 bg-black text-white' style={{ height: "50vh" }}>
                <h3 className='pt-3 fw-bold text-center'>{props.contract}</h3>
                <p className='mb-auto p-2 fs-2 text-center'> {props.keysentense} </p>
                <p className='text-center align-items-end'>
                    <span className='fs-1'>{props.price}</span>
                    <span>/{props.period}</span>
                </p>
                <p> {props.info}</p>
            </div>
        </>
    }

    return (
        <Section
            color="white"
            body={
                <>
                    <div className='col-md-12 text-center'>
                        <h1 data-aos="zoom-in" className='display-2'>Unsere Preise.</h1>
                    </div>
                    <Slider {...settings}>
                        <PriceCard
                            contract="1/2 Jahres-ABO"
                            price="39,90 € "
                            keysentense="Für Kurzentschlossene."
                            period="Monat" />
                        <PriceCard
                            contract="Jahres-ABO"
                            price="34,90 € "
                            keysentense="Für Langentschlossene."
                            period="Monat" />
                        <PriceCard
                            contract="Partnervertrag"
                            price="59,90 € "
                            keysentense="Für Fitnesscouple."
                            period="Monat" />
                        <PriceCard
                            contract="Monatskarte"
                            price="49,90 € "
                            period="Monat"
                            keysentense="Für Urlauber."
                        />
                        <PriceCard
                            contract="Tageskarte"
                            price="15,00 € "
                            period="Tag"
                            keysentense="Für Eintagsfliegen."
                        />
                        <PriceCard
                            contract="10'er Karte"
                            price="100,00 € "
                            period="Karte"
                            keysentense="Für unsere unregelmäßigen Gäste."
                        />
                    </Slider>
                    <div className='col-md-6 pt-4 text-center'>
                        <h2 data-aos="zoom-in" className='display-3'>Probetraining.</h2>
                        <p>
                            Bevor du dich für einen unserer Verträge entscheidest, kannst du unverbindlich an einem Probetraining teilnehmen.
                            Da ein Probetraining und eine Studioführung etwas Zeit in Anspruch nimmt, solltest du  einen Termin mit uns vereinbaren.
                        </p>
                    </div>
                    <div className='col-md-6 pt-4 text-center'>
                        <h2 data-aos="zoom-in" className='display-3'>In jeder Option.</h2>
                        <p>
                            Im Preis enthalten sind sämtliche Leistungen wie das Duschen, die Umkleideräume und alle Sportgeräte + Equipment die sich im Sportstudio befinden, sowie sämtlich angebotene auf sie zugeschnittene Trainingspläne.
                        </p>
                    </div>
                    <div className="col-md-12 mt-3 text-center">
                        <Button text="LEGENDE WERDEN!" size="btn-lg" customClasses="m-1" onClick='/kontakt' />
                    </div>
                </>
            }
        />
    );
}

export default Prices;