import React from "react";
import PropTypes from "prop-types";
import "./YoutubeEmbed.css"

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive cookieconsent-optin-marketing">
    <iframe
      data-cookieconsent="marketing"
      width="853"
      height="480"
      data-cookieblock-src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
