import './Navbar.css'
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

/**
 * Funktion zum Erstellen des Headers
 * @returns navbar
 */


function Navbar() {

    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <nav className="navbar navbar_custom">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img className="logo" src='images/east_side_mecca_white.png' alt="logo" />
                    </a>
                    <span style={{ zIndex: 1046 }}>
                        <Hamburger toggled={isOpen} toggle={setOpen} color="#FFF" />
                    </span>
                    <Offcanvas className="text-bg-dark ps-3 bg-black" show={isOpen} onHide={setOpen} placement="top">
                        <Offcanvas.Body className='d-flex align-items-center ps-0'>
                            <ul className="nav flex-column menu">
                                <li className="nav-item">
                                    <a className="nav-link active text-white ps-0 fs-4" aria-current="page" href="/gym">Das Gym</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active text-white ps-0 fs-4" aria-current="page" href="/personal-training">Personal Training</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active text-white ps-0 fs-4" aria-current="page" href="/preise-und-oeffnungszeiten">Preise & Öffnungzeiten</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active text-white ps-0 fs-4" aria-current="page" href="/anfahrt">Anfahrt & Parken</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active text-white ps-0 fs-4" aria-current="page" href="/kontakt">Kontakt</a>
                                </li>
                            </ul>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            </nav>
        </>

    )
}

export default Navbar
