import React from 'react';
import Section from './Section';

function OpeningHours(props) {
    return (
        <Section
            color="black"
            body={
                <>
                    <div className='col-md-12 text-center my-auto'>
                        <h1 data-aos="zoom-in" className='display-2 red-font'>Fuer euch offen.</h1>
                    </div>
                    <div className='col-md-12 text-white text-center md-auto'>
                        <div>
                            <ul className="list-group">
                                <li className="list-group-item bg-black text-white p-0 fs-4">Mo - Fr	09.00 Uhr - 22.00 Uhr</li>
                                <li className="list-group-item bg-black text-white p-0 fs-4">Samstag	10.00 Uhr -	16.00 Uhr</li>
                                <li className="list-group-item bg-black text-white p-0 fs-4">Sonntag	10.00 Uhr - 14.00 Uhr</li>
                                <li className="list-group-item bg-black text-white p-0 fs-4">Feiertags	10.00 Uhr - 14.00 Uhr</li>
                            </ul>
                        </div>
                    </div>
                </>
            }
        />
    );
}

export default OpeningHours;