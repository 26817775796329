import React from 'react'
import Section from './Section';
import Image from "../images/personal/personal_2.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


function Personal() {
  return (
    <Section
      color="white"
      body={
        <>
          <div className="col-md-6 my-auto p-4 text-center">
            <LazyLoadImage src={Image}
              className="img-fluid"
              alt="personal_training"
              data-aos="zoom-in"
            />
          </div>
          <div className="col-md-6 my-auto">
              <h1 data-aos="zoom-in" className="display-4 red-font"> Findest du dich hier wieder? </h1>
              <p>
                Wieder nicht geschafft dich fürs Studio zu motivieren? <br />
                Schlechtes Gewissen, weil Ernährung und Sport auf der Strecke geblieben sind? <br />
                Nicht genügend Zeit, um dich selbst um Ernährung und Sport zu kümmern? <br />
                Uzufrieden mit deiner Figur? < br />
                Du leidest unter Rückenschmerzen und/oder Verspannungen?
              </p>
            </div>
        </>
      }
    />
  )
}

export default Personal