import React from 'react'
import DefaultPage from './DefaultPage'
import Follow from '../Follow';
import ContactForm from '../ContactForm';


/**
 * Funktion zum Erstellen der Seite: Kontakt 
 * @returns contact site
 */
function Contact() {

    return (
        < DefaultPage
            sections={
                <>
                    <ContactForm></ContactForm>
                    <Follow></Follow>
                </>
            }
        />
    )
}

export default Contact
