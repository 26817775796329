import React from 'react'
import YoutubeEmbed from "./YoutubeEmbed";
import Section from './Section';

/**
 * Aus Video Tutorial (siehe Quellen) übernommen und angepasst. Nicht Teil der Bewertung. Diente zum Erlenen von React.
 * Sollte zum Ende noch in Bootstrap übersetzt werden. Leider fehlte die Zeit. Beispiel für Cards unter Nutzung von Bootstrap: SideWidgets, Blog 
 * @returns cardItem
 */

function Whois() {
    return (
        <Section
            color="black"
            body={
                <>
                    <div className='co-md-12 text-center'>
                        <h1 data-aos="zoom-in" className="display-4"> Wer sind wir? </h1>
                    </div>
                    <div className="col-md-12">
                        <figure data-aos="zoom-in" className="embed-responsive embed-responsive-16by9 float-right pt-2">
                            <YoutubeEmbed embedId="ECq6RcAbti0" />
                            <div class="cookieconsent-optout-marketing">
                                Hier wäre eigentlich ein Video. Bitte akzeptiere Marketing-Cookies um das Video zu schauen.
                            </div>
                        </figure>
                        <p> Seit der Eröffnung im Jahre 2000 steht das Body & Fight GYM in Potsdam für Fitnessbegeisterte & leistungsorientiertes Bodybuilding mit fachkompetenter Beratung vom Anfänger bis zum Wettkämpfer.
                            Nicht nur für ambitionierte Athleten ist unser umfangreich ausgestattetes Studio einer der Hot Spots in Potsdam und Brandenburg.
                            So dürfen wir oft Gäste aus dem In- und Ausland begrüßen, die "einmal" bei uns trainieren möchten. Insbesondere Freizeitsportler fühlen sich in der leistungsfördernden und lockeren Atmosphäre schnell wie zu Hause.
                            Nicht umsonst sind wir seit über 21 Jahren am Markt erfolgreich. Schauen Sie einfach mal bei uns rein und genießen Sie die Atmosphäre im East Side Mecca.
                        </p>
                    </div>
                </>
            }
        />
    )
}

export default Whois
