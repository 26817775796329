import React from 'react';
import './DefaultPage.css';
import Prices from '../Prices';
import OpeningHours from '../OpeningHours';
import DefaultPage from './DefaultPage';
import Follow from '../Follow';
import HeroSection from '../HeroSection';
import heroVideo from '../../videos/cardio.mp4'

function Opening(props) {
    return (
        <>
            <DefaultPage sections={
                <>
                    <HeroSection
                        heroVideo={heroVideo}
                        body={
                            <>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1">LEGENDS</h1>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h1 data-aos="zoom-in" className="display-3">ARE BUILT AT THE</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1 red-font">EASTSIDE</h1>
                                </div>
                                <div className="row">
                                    <h1 data-aos="zoom-in" className="display-1 red-font">MECCA</h1>
                                </div>
                            </>
                        }
                    />
                    <Prices></Prices>
                    <OpeningHours></OpeningHours>
                    <Follow></Follow>
                </>
            }
            />
        </>
    );
}

export default Opening;