import React from 'react'
import Section from './Section';
import { Button } from './Button';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../images/personal/personal_1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


function Personal() {
  return (
    <Section
      color="black"
      body={
        <>
          <div className="col-md-6 my-auto">
            <div className="text-center">
              <h1 data-aos="zoom-in" className="display-4 red-font"> Personal Training. </h1>
              <p>
                Du suchst dein wahres Limit oder willst deine Ernährung anpassen? <br />
                Dann ist unser Personal Training genau das richtige für dich. Erhalte deinen persönlichen Trainingsplan und trainiere mit dem mehrfachen deutschen Meister und MR.UNIVERSUM Andreas Bosse.<br />
              </p>
              <Button text="BOSSE LIFE!" size="btn-lg" customClasses="" onClick='/personal-training' />
            </div>
          </div>
          <div className="col-md-6 my-auto p-4 text-center">
            <LazyLoadImage src={Image}
              className="img-fluid"
              alt="personal_training"
              data-aos="zoom-in"
            />
          </div>
        </>
      }
    />
  )
}

export default Personal
